import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import { Grid, TableHeaderRow, Table } from '@devexpress/dx-react-grid-material-ui';
import { DataTypeProvider, SortingState } from '@devexpress/dx-react-grid';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import Spinner from 'shared/components/andtComponents/Spinner';
import { BillingHistoryRow } from '../../types.ts';
import styles from '../billingSummary.module.scss';
import { getTableColumnsAndWidths, LINES_IN_PAGE } from '../../utils.ts';
import { useFlexibilityMargin } from '../../../Customers/hooks/useCustomers';
import { FLEXIBILITY_TABLE_COLUMNS } from '../../../../constants/customersConstants';
import { CurrencyNumberWithTooltipFormatter } from '../CustomTableComponents';
import MarginsTableFilters from './MarginsTableFilters.tsx';

export interface IndexProps {
  row: BillingHistoryRow;
  timeRange: [Date, Date];
}

const labelsToShow = [
  'riFlexibilityMargin',
  'spFlexibilityMargin',
  'publicPriceMargin',
  'billingRuleMargin',
  'creditMargin',
  'resellerCredit',
];
const columnsWidths = [
  { columnName: 'linkedAccountName', width: 450 },
  { columnName: 'marginType', width: 120 },
  { columnName: 'service', width: 150 },
];
const weirdTypeToRequestMap = {
  riFlexibilityMargin: 'ri',
  spFlexibilityMargin: 'sp',
  publicPriceMargin: 'pp',
  billingRuleMargin: 'billingRules',
  creditMargin: 'creditMargin',
  resellerCredit: 'resellerCredit',
};
export const CustomerMargins: React.FC<IndexProps> = ({ row, timeRange: [startDate, endDate] }) => {
  const { usersStore } = useRootStore();
  const { getCurrencyNumber } = useUserSettingsContext();
  const [activeKey, setActiveKey] = useState(labelsToShow[0]);
  const activeKeyAlias = weirdTypeToRequestMap[activeKey];
  const [currentPage, setCurrentPage] = useState(0);

  const columnsButtonsMap = useMemo(() => {
    const { tableColumns } = getTableColumnsAndWidths(usersStore.currDispUserCloudAccountType === CLOUD_TYPE_IDS.AZURE);
    return tableColumns.reduce((acc, cur) => {
      if (labelsToShow.includes(cur.name)) {
        acc[cur.name] = {
          key: cur.name,
          label: cur.title,
          value: row[cur.name],
        };
      }
      return acc;
    }, {});
  }, [usersStore.currDispUserCloudAccountType]);

  const { getFlexibilityMarginData } = useFlexibilityMargin();
  const { data: marginsData = [], isLoading } = getFlexibilityMarginData({
    divisionId: row.divisionId,
    divisionName: row.customerName,
    accountKey: row.accountKey,
    flexibilityType: activeKeyAlias,
    startDate,
    endDate,
  });

  const columns = FLEXIBILITY_TABLE_COLUMNS(activeKeyAlias);
  const rowsCount = marginsData?.length || 0;
  const rowsWithPagination = (marginsData || []).slice(LINES_IN_PAGE * currentPage, LINES_IN_PAGE * (currentPage + 1));

  return (
    <div className={styles.billingMargins}>
      <div className="d-flex mb-4">
        {labelsToShow.map((key) => (
          <button
            type="button"
            className={classNames(styles.gradeButton, key === activeKey && styles.active)}
            key={key}
            onClick={() => setActiveKey(key)}
          >
            <span>{columnsButtonsMap[key]?.label || key}:</span>
            <span>{getCurrencyNumber(columnsButtonsMap[key]?.value)}</span>
          </button>
        ))}
      </div>
      {isLoading ? (
        <Spinner position="relative" />
      ) : (
        <>
          <MarginsTableFilters
            rowName={row.customerName}
            tableData={marginsData}
            headerText={columnsButtonsMap[activeKey].label}
            rowsCount={rowsCount}
            onPageChange={(newPage) => setCurrentPage(newPage)}
            currentPage={currentPage}
            totalPages={Math.ceil(rowsCount / LINES_IN_PAGE)}
          />
          <div className={styles.marginsGrid}>
            <Grid rows={rowsWithPagination} columns={columns}>
              <SortingState defaultSorting={[{ columnName: 'linkedAccountName', direction: 'desc' }]} />
              <Table columnExtensions={columnsWidths} />
              <DataTypeProvider for={['margin']} formatterComponent={CurrencyNumberWithTooltipFormatter} />
              <TableHeaderRow showSortingControls={!!rowsCount} />
            </Grid>
          </div>
        </>
      )}
    </div>
  );
};

export default CustomerMargins;
