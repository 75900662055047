import React, { useEffect, useState } from 'react';
import CustomModal from 'shared/components/andtComponents/Modal';
import Spinner from 'shared/components/andtComponents/Spinner';
import useTable from 'shared/hooks/customHooks/useTable';
import useRoles from 'users/new-user-management/hooks/reactQuery/useRoles';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common/dist';
import SharedEntitiesModalTable from './SharedEntitiesModalTable';
import SharedEntitiesModalHeader from './SharedEntitiesModalHeader';
import { PERMISSION_ENTITIES } from '../../../consts';

import styles from './SharedEntitiesModal.module.scss';

const SharedEntitiesModal = ({ isOpen, setIsOpen, actionId, category, roleInternalName }) => {
  const [search, setSearch] = useState('');
  const [entitiesPartialPermissions, setEntitiesPartialPermissions] = useState(null);

  const { NewTableWrapper } = useTable();

  const { fetchPartialRolePermissions } = useRoles(isOpen);
  const { data, isLoading } = fetchPartialRolePermissions({
    actionId,
    category,
    roleInternalName,
  });

  const partialPermissions = data?.partialPermissions;

  useEffect(() => {
    if (partialPermissions) {
      const tmpEntities = partialPermissions?.reduce((tmpEntities, { entityId, derivedFromRole }) => {
        if (!tmpEntities[entityId]) {
          // eslint-disable-next-line no-param-reassign
          tmpEntities[entityId] = { entityId, derivedFromRole: [] };
        }
        tmpEntities[entityId].derivedFromRole.push(derivedFromRole);
        return tmpEntities;
      }, {});
      let entitiesArray = Object.values(tmpEntities);
      if (search) {
        entitiesArray = entitiesArray.filter((e) => e.entityId.toLowerCase().includes(search.toLowerCase()));
      }
      setEntitiesPartialPermissions(entitiesArray);
    }
  }, [partialPermissions, search]);

  return (
    <CustomModal
      cancelTitle="Close"
      className={styles}
      onClose={() => {
        setIsOpen(false);
      }}
      onCloseClick={() => {
        setIsOpen(false);
      }}
      open={isOpen}
      automationId="shared-items-of-role-modal"
      saveHidden
    >
      <NewTableWrapper className="sub-table" isCompact>
        <SharedEntitiesModalHeader search={search} setSearch={setSearch} category={category} />
        <div className={styles.note}>
          <GenerateIcon iconName={ICONS.circleInfo.name} className={styles.infoIcon} />
          <div>
            {`The list contains ${PERMISSION_ENTITIES[category].title} that were shared with this role and underlying
               roles. To detach them, go to the entity and change the sharing options.`}
          </div>
        </div>
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            {entitiesPartialPermissions ? (
              <SharedEntitiesModalTable partialPermissions={entitiesPartialPermissions} />
            ) : null}
          </>
        )}
      </NewTableWrapper>
    </CustomModal>
  );
};

export default SharedEntitiesModal;
