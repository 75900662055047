import { API } from 'shared/utils/apiMiddleware';
import { isNil } from 'lodash';
import { BILLINGS_API_NAME, USER_MANAGEMENT_ROLES } from './helperFunctions';

export async function fetchRoles(params) {
  const queryString = params
    ? Object.keys(params)
        ?.filter((key) => !isNil(params[key]))
        ?.map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
        ?.join('&')
    : null;
  try {
    return API.get(BILLINGS_API_NAME, `${USER_MANAGEMENT_ROLES}${queryString ? `?${queryString}` : ''}`);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function deleteRoles(roleInternalNames) {
  const payload = {
    body: { roleInternalNames },
  };
  try {
    return API.del(BILLINGS_API_NAME, `${USER_MANAGEMENT_ROLES}`, payload);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchRoleByInternalName(roleInternalName) {
  try {
    const encodedName = encodeURIComponent(roleInternalName);
    return API.get(BILLINGS_API_NAME, `${USER_MANAGEMENT_ROLES}/${encodedName}`);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchRoleDataAccess(roleInternalName) {
  try {
    const encodedName = encodeURIComponent(roleInternalName);
    return API.get(BILLINGS_API_NAME, `${USER_MANAGEMENT_ROLES}/${encodedName}/data-access`);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function setRoleDataAccess(roleInternalName, roleDataAccess) {
  const payload = {
    body: { roleDataAccess },
  };
  try {
    const encodedName = encodeURIComponent(roleInternalName);
    return API.post(BILLINGS_API_NAME, `${USER_MANAGEMENT_ROLES}/${encodedName}/data-access`, payload);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchRolePermissions(roleInternalName) {
  try {
    const encodedName = encodeURIComponent(roleInternalName);
    return API.get(BILLINGS_API_NAME, `${USER_MANAGEMENT_ROLES}/${encodedName}/permissions`);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function setRolePermissions(roleInternalName, roleCategoryPermissions) {
  const payload = {
    body: { roleCategoryPermissions },
  };
  try {
    const encodedName = encodeURIComponent(roleInternalName);
    return API.put(BILLINGS_API_NAME, `${USER_MANAGEMENT_ROLES}/${encodedName}/permissions`, payload);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchPartialRolePermissions(roleInternalName, category, actionId) {
  try {
    const encodedName = encodeURIComponent(roleInternalName);
    return API.get(BILLINGS_API_NAME, `${USER_MANAGEMENT_ROLES}/${encodedName}/permissions/${category}/${actionId}`);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function addPartialRolePermissions(roleInternalName, category, action, rolePartialPermission) {
  const payload = {
    body: { rolePartialPermission },
  };
  try {
    const encodedName = encodeURIComponent(roleInternalName);
    return API.put(
      BILLINGS_API_NAME,
      `${USER_MANAGEMENT_ROLES}/${encodedName}/permissions/${category}/${action}`,
      payload,
    );
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function deletePartialRolePermissions(roleInternalName, category, action, rolePartialPermission) {
  const payload = {
    body: { rolePartialPermission },
  };
  try {
    const encodedName = encodeURIComponent(roleInternalName);
    return API.del(
      BILLINGS_API_NAME,
      `${USER_MANAGEMENT_ROLES}/${encodedName}/permissions/${category}/${action}`,
      payload,
    );
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function addSubRolesToRole(roleInternalName, subRolesInternalNames) {
  const payload = {
    body: { subRolesInternalNames },
  };
  try {
    const encodedName = encodeURIComponent(roleInternalName);
    return API.post(BILLINGS_API_NAME, `${USER_MANAGEMENT_ROLES}/${encodedName}/sub-roles`, payload);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function removeSubRolesFromRole(roleInternalName, subRolesInternalNames) {
  const payload = {
    body: { subRolesInternalNames },
  };
  try {
    const encodedName = encodeURIComponent(roleInternalName);
    return API.del(BILLINGS_API_NAME, `${USER_MANAGEMENT_ROLES}/${encodedName}/sub-roles`, payload);
  } catch (error) {
    return Promise.reject(error);
  }
}
