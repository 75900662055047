import React from 'react';
import PageHeader from 'shared/components/PageHeader';
import { Container } from 'reactstrap';
import { PageNames } from '../../../shared/constants/appConstants';
import CustomersHistoryTable from './components/CustomersHistory';

const BillingSummary: React.FC = () => (
  <Container>
    <PageHeader showDate title={PageNames.BILLING_SUMMARY} barIcons isBeta />
    <CustomersHistoryTable />
  </Container>
);

export default BillingSummary;
