import React, { useMemo, useState } from 'react';
import { GenerateIcon, ICONS, Popover, PopoverContent, PopoverTrigger } from '@pileus-cloud/anodot-frontend-common';
import SwitchButton from 'shared/components/andtComponents/Switch';
import Button from 'shared/components/andtComponents/Button';
import { PERMISSION_TYPES } from '../../consts';

import styles from './Permission.module.scss';
import SharedEntitiesModal from './SharedItemsModal/SharedEntitiesModal';

const Permission = ({ actionId, category, onPermissionChange, permissions, roleInternalName }) => {
  const [isSharedEntitiesModalOpen, setIsSharedEntitiesModalOpen] = useState(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const handleMouseEnter = () => {
    setIsTooltipOpen(true);
  };

  const handleMouseLeave = () => {
    setIsTooltipOpen(false);
  };

  const permissionTypeAndRoles = useMemo(() => {
    let maxPermissionType = PERMISSION_TYPES.NONE.id;
    const fullPermissionRoles = [];
    permissions?.forEach((permission) => {
      if (permission.permissionType === PERMISSION_TYPES.FULL.id) {
        if (permission.derivedFromRole) {
          fullPermissionRoles.push(permission.derivedFromRole);
        }
        maxPermissionType = PERMISSION_TYPES.FULL.id;
      }
      if (permission.permissionType === PERMISSION_TYPES.PARTIAL.id && maxPermissionType !== PERMISSION_TYPES.FULL.id) {
        maxPermissionType = PERMISSION_TYPES.PARTIAL.id;
      }
    });
    return {
      type: maxPermissionType,
      roles: maxPermissionType === PERMISSION_TYPES.FULL.id ? fullPermissionRoles : null,
    };
  }, [permissions]);

  const PermissionTooltip = ({ roles }) => (
    <div className={styles.permissionTooltip}>
      <div className={styles.tooltipTitle}>This permission was granted via these roles:</div>
      <ul>
        {roles?.map((role) => (
          <li key={role}>{role?.displayName}</li>
        ))}
      </ul>
      <div className={styles.tooltipComment}>Detach these roles to remove this permission</div>
    </div>
  );

  return (
    <>
      {permissionTypeAndRoles.type === PERMISSION_TYPES.FULL.id && (
        <>
          {permissionTypeAndRoles.roles?.length === 0 ? (
            <SwitchButton onChange={onPermissionChange} isChecked className={styles.permissionSwitch} isSmall />
          ) : (
            <Popover open={isTooltipOpen} onOpenChange={setIsTooltipOpen}>
              <PopoverTrigger onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <GenerateIcon iconName={ICONS.check.name} className={styles.permissionIcon} />
              </PopoverTrigger>
              {permissionTypeAndRoles?.roles?.length > 0 ? (
                <PopoverContent>
                  <PermissionTooltip roles={permissionTypeAndRoles.roles} />
                </PopoverContent>
              ) : null}
            </Popover>
          )}
        </>
      )}
      {permissionTypeAndRoles.type === PERMISSION_TYPES.NONE.id && (
        <SwitchButton onChange={onPermissionChange} isChecked={false} className={styles.permissionSwitch} isSmall />
      )}
      {permissionTypeAndRoles.type === PERMISSION_TYPES.PARTIAL.id && (
        <>
          <Button
            text="Shared Items"
            onClick={() => setIsSharedEntitiesModalOpen(!isSharedEntitiesModalOpen)}
            className={{ buttonText: styles.sharedItemsText }}
            isTextButton
          />
          <SwitchButton onChange={onPermissionChange} isChecked={false} className={styles.permissionSwitch} isSmall />
          {isSharedEntitiesModalOpen && (
            <SharedEntitiesModal
              actionId={actionId}
              category={category}
              isOpen={isSharedEntitiesModalOpen}
              roleInternalName={roleInternalName}
              setIsOpen={setIsSharedEntitiesModalOpen}
            />
          )}
        </>
      )}
    </>
  );
};

export default Permission;
