import React from 'react';
import classNames from 'classnames';
import { GET_USERS_QUERY_PARAMS } from '../../../consts';

import styles from './SharedRoles.module.scss';

const SharedRoles = ({ row }) => {
  const derivedFromRoles = row?.derivedFromRole;

  const onRoleClick = (roleDisplayName) => {
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set(GET_USERS_QUERY_PARAMS.SEARCH.name, roleDisplayName);
    window.open(currentUrl.toString(), '_blank');
  };

  return (
    <div className={styles.derivedFromRolesContainer}>
      {derivedFromRoles?.map((role) => (
        <div
          className={classNames(styles.derivedFromRole, { [styles.derivedFromSelf]: !role })}
          onClick={() => onRoleClick(role?.displayName)}
        >
          {role ? role?.displayName : 'Direct'}
        </div>
      ))}
    </div>
  );
};

export default SharedRoles;
