import { useCallback, useState } from 'react';
import { b64toBlob } from 'shared/utils/strUtil';
import fileSaver from 'file-saver';

export type ExportRowsPayload = { rows: any[]; type: 'pdf' | 'csv' };

const useExportRows = (fileName: string, fetcher, onDone?: (data: any) => void) => {
  const [subjectToDownload, setSubjectToDownload] = useState<ExportRowsPayload | null>(null);

  const { refetch, isFetching } = fetcher({
    onSuccess: (data) => {
      fileSaver.saveAs(b64toBlob(data), `${fileName || 'document'}-${subjectToDownload?.type}.zip`);
      setSubjectToDownload(null);
      onDone?.(data);
    },
    rows: subjectToDownload?.rows,
    type: subjectToDownload?.type,
    enabled: false,
  });

  const exportRows = useCallback(
    (p: ExportRowsPayload) => {
      setSubjectToDownload(p);
      setTimeout(() => refetch(), 500);
    },
    [refetch],
  );

  return { isExporting: isFetching, exportRows };
};

export default useExportRows;
