/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import Spinner from 'shared/components/andtComponents/Spinner';

export const checkTrialUser = (Component1, Component2, childProps) => {
  const Page = observer(({ history, location }) => {
    if (childProps.usersStore.usersModel.isLoadingUsers) {
      return <Spinner />;
    }
    if (
      childProps.usersStore &&
      childProps.usersStore.currentDisplaydUser &&
      childProps.usersStore.currentDisplaydUser.isPayingUser
    ) {
      return <Component1 {...childProps} history={history} location={location} />;
    }
    return <Component2 {...childProps} history={history} location={location} />;
  });

  Page.propTypes = {
    history: PropTypes.object.isRequired,
  };

  return Page;
};
