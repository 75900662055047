import React from 'react';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common/dist';
import Input from 'shared/components/andtComponents/Input';
import Button from 'shared/components/andtComponents/Button';
import Pagination from 'shared/components/andtComponents/Pagination';
import DatePickerFilter from 'shared/components/DatePickerFilter';
import { CSVLink } from 'react-csv';
import classes from '../billingSummary.module.scss';
import { BillingHistoryRow } from '../../types.ts';
import { prepareCsvModifiedRows } from '../../utils.ts';

const InputWithNoTypes = Input as React.FC<any>;

type Props = {
  searchText?: string | null;
  setSearchText(txt: string): void;
  isExpanded: boolean;
  setIsExpanded(d: boolean): void;
  onPageChange(newIndex: number): void;
  currentPage: number;
  totalPages: number;
  headerText?: string;
  onDateChange(s: Date, e: Date): void;
  startDate: Date;
  endDate: Date;
  tableData: BillingHistoryRow[];
};
const HistoryTableFilters: React.FC<Props> = ({
  searchText,
  setSearchText,
  isExpanded,
  setIsExpanded,
  onPageChange,
  currentPage,
  totalPages,
  headerText = '',
  onDateChange,
  startDate,
  endDate,
  tableData,
}) => (
  <div className={classes.tableFilters}>
    <div>
      <Button
        automationId="expand-rows"
        text=""
        isSecondary
        icon={() => <GenerateIcon iconName={isExpanded ? ICONS.collapse.name : ICONS.expand.name} />}
        onClick={() => {
          setIsExpanded(!isExpanded);
        }}
      />
      <span className={classes.headerText}>{headerText}</span>
    </div>

    <div className={classes.rightMenu}>
      <DatePickerFilter
        isDateRangeError={false}
        onDateChange={({ startDate, endDate }) => {
          onDateChange?.(startDate, endDate);
        }}
        startDate={startDate}
        endDate={endDate}
        isMaxDateNeeded={false}
        currPeriodGranLevel="month"
        dateFormat="MMM YYYY"
        andtLook
      />
      <div className="position-relative d-flex align-items-center">
        <InputWithNoTypes
          className={classes.input}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          placeholder="Search"
          name="search-recommendations"
          isSearch
          searchComponent={<GenerateIcon iconName={ICONS.search.name} />}
          type="text"
        />
      </div>
      <CSVLink data={prepareCsvModifiedRows(tableData)} headers={null} filename="billing-summary.csv">
        <div className={classes.downloadLinkButton} title="Export CSV">
          <GenerateIcon iconName={ICONS.download.name} /> Table Summary
        </div>
      </CSVLink>
      <Pagination currPageIdx={currentPage} onPageChange={onPageChange} totalPages={totalPages} />
    </div>
  </div>
);

export default HistoryTableFilters;
