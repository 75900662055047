import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { SelectMulti, GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common/dist';
import { withRolesContextConsumer } from 'users/contexts/RolesProvider';
import CustomModal from 'shared/components/andtComponents/Modal';
import { prepareCloudHirarcheyObject } from 'users/utils/rolesUtil';
import { withLinkedAccountsContextConsumer } from 'users/contexts/LinkedAccountsProvider';
import useFormattedLinkedAccounts from 'users/containers/RolesAndUsers/hooks/useFormattedLinkedAccounts';
import useRoles from 'users/hooks/react-query/useRoles';
import { isAccountAllAccounts } from 'users/utils/userUtil';
import { useRootStore } from 'app/contexts/RootStoreContext';
import Button from 'shared/components/andtComponents/Button';
import styles from './MultipleAssignLinkedAccountsModal.module.scss';
import AccountsChooser from '../AccountsChooser/AccountsChooser';
import { ROLE_TYPES } from '../../constants/rolesAndUsersConstants';
import PermissionIcon from '../PermissionIcon';

const createRoleOptions = (roles) => roles?.map((role) => ({ label: role.roleName, value: role.uuid }));

const MultipleAssignLinkedAccountsModal = ({
  onClose,
  roles,
  refetchRoles,
  cloudAccountsLinkedAccount: linkedAccountsRaw,
}) => {
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const { updateRolesBatch } = useRoles();
  const roleOptions = useMemo(
    () => createRoleOptions(roles.filter((role) => role.roleType !== ROLE_TYPES.DEFAULT_ADMIN)),
    [roles],
  );
  const cloudAccountsLinkedAccount = useFormattedLinkedAccounts(linkedAccountsRaw);
  const { mutateAsync: handleUpdateBatch } = updateRolesBatch();
  const { usersStore } = useRootStore();
  const options = useMemo(
    () =>
      prepareCloudHirarcheyObject(
        usersStore.getAllCurrDisplayedUserAccounts().filter((acc) => !isAccountAllAccounts(acc)),
      ),
    [usersStore.getAllCurrDisplayedUserAccounts()],
  );
  const handleSaveRoles = async () => {
    const params = {
      roles: selectedRoles.map((roleOption) => {
        const role = roles.find((r) => r.uuid === roleOption.value);
        return {
          roleName: role.roleName,
          roleUserType: role.roleUserType,
          roleType: 'userRole',
          useSameAccountKey: true,
          createdBy: role.createdBy,
          uuid: role.uuid,
        };
      }),
      accounts,
    };
    await handleUpdateBatch(params);
    await refetchRoles();
    onClose();
  };
  return (
    <CustomModal
      saveDisabled={!selectedRoles.length || !accounts.length}
      className={styles.modal}
      title=""
      saveTitle="Apply"
      open
      onClose={onClose}
      onSave={handleSaveRoles}
      automationId="roleBatchAccountChooser"
    >
      <div className={styles.container}>
        <div>
          <p className={styles.label}>Linked Accounts</p>
          <AccountsChooser
            position="bottom"
            selectedAccounts={accounts}
            setSelectedAccounts={setAccounts}
            accounts={options}
            linkedAccounts={cloudAccountsLinkedAccount}
          />
        </div>
        <div>
          <p className={styles.label}>Roles</p>
          <SelectMulti
            label="Roles"
            selected={selectedRoles}
            options={roleOptions}
            handleSelectionChange={(role) => {
              setSelectedRoles(role);
            }}
          />
          <div className={styles.roles}>
            {selectedRoles.map(({ value }) => {
              const role = roles.find((r) => r.uuid === value);
              return (
                <div key={value} className={styles.roleOption}>
                  <div>
                    <PermissionIcon role={role} />
                    {role.roleName}
                  </div>
                  <Button
                    onClick={() => {
                      setSelectedRoles(selectedRoles.filter((r) => r.value !== value));
                    }}
                    text=""
                    isTextButton
                    icon={() => <GenerateIcon iconName={ICONS.delete.name} />}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

MultipleAssignLinkedAccountsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  refetchRoles: PropTypes.func.isRequired,
  cloudAccountsLinkedAccount: PropTypes.object.isRequired,
};

export default withLinkedAccountsContextConsumer(withRolesContextConsumer(MultipleAssignLinkedAccountsModal));
