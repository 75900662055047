import React from 'react';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common/dist';
import SwitchButton from 'shared/components/andtComponents/Switch';

import styles from './PermissionsExplanation.module.scss';

const PermissionsExplanation = () => (
  <div className={styles.permissionsExplanationContainer}>
    <div className={styles.permissionExplanationTitle}>Permissions Explained</div>
    <div className={styles.permissionExplanationContainer}>
      <div className={styles.permissionExplanation}>
        <div className={styles.permissionTitle}>
          Permissioned <GenerateIcon iconName={ICONS.check.name} className={styles.permissionIcon} />
        </div>
        <div className={styles.permissionText}>
          The permission was granted by underlying roles. Detach these roles to remove the permission.
        </div>
      </div>
      <div className={styles.permissionExplanation}>
        <div className={styles.permissionTitle}>
          ON/OFF Toggle
          <SwitchButton onChange={undefined} isChecked isDisabled className={styles.permissionSwitch} isSmall />
        </div>
        <div className={styles.permissionText}>Set this permission to on/off in this role.</div>
      </div>
      <div className={styles.permissionExplanation}>
        <div className={styles.permissionTitle}>Shared items</div>
        <div className={styles.permissionText}>
          Specific instances of this entity were shared with this role, click to view them.
        </div>
      </div>
    </div>
  </div>
);

export default PermissionsExplanation;
