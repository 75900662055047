import React, { useEffect, useMemo, useState } from 'react';
import {
  DataTypeProvider,
  IntegratedSelection,
  RowDetailState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import { Grid, TableHeaderRow, TableRowDetail, TableSelection } from '@devexpress/dx-react-grid-material-ui';
import TableWrapper from 'shared/components/tables/TableWrapper';
import useTable from 'shared/hooks/customHooks/useTable';
import { CSVLink } from 'react-csv';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import classes from '../billingSummary.module.scss';
import {
  CurrencyNumberWithTooltipFormatter,
  CustomHeaderCell,
  CustomNoDataComponent,
  CustomSelectionCell,
  CustomToggleCell,
} from '../CustomTableComponents';
import CustomerMargins from '../CustomerMargins';
import { BillingHistoryRow } from '../../types.ts';
import { getTableColumnsAndWidths, prepareCsvModifiedRows, prepareServerExportRows } from '../../utils.ts';
import { ExportRowsPayload } from '../useExportRows.ts';

const columnsToApplyCurrencyFormatting = [
  'mtdResellerCost',
  'mtdCustomerCost',
  'partnerMargin',
  'riMargin',
  'usageMargin',
  'riFlexibilityMargin',
  'spFlexibilityMargin',
  'publicPriceMargin',
  'billingRuleMargin',
  'creditMargin',
  'resellerCredit',
];

type Props = {
  rows: BillingHistoryRow[];
  selectedRowsIndexes: number[];
  setSelectedRowsIndexes(rs: number[]): void;
  isAzure: boolean;
  isAllExpanded: boolean;
  timeRange: [Date, Date];
  exportRows(payload: ExportRowsPayload): void;
};

const CustomersHistoryTable: React.FC<Props> = ({
  rows,
  selectedRowsIndexes,
  setSelectedRowsIndexes,
  isAzure,
  isAllExpanded,
  timeRange,
  exportRows,
}) => {
  const [expandedRowIds, setExpandedRowIds] = useState<(string | number)[]>([]);
  const { NewTableRow, NewTableSelectionBar } = useTable();
  const { tableColumns } = getTableColumnsAndWidths(isAzure);

  useEffect(() => {
    if (!isAllExpanded) {
      setExpandedRowIds([]);
    } else {
      setExpandedRowIds(rows.map((row, index) => index));
    }
  }, [isAllExpanded]);

  const selectedRows = useMemo(
    () => rows.filter((row, index) => selectedRowsIndexes.includes(index)),
    [rows, selectedRowsIndexes],
  );

  return (
    <Grid rows={rows} columns={tableColumns}>
      <SortingState
        defaultSorting={[
          { columnName: 'year', direction: 'desc' },
          { columnName: 'month', direction: 'desc' },
          { columnName: 'mtdResellerCost', direction: 'desc' },
          { columnName: 'mtdCustomerCost', direction: 'desc' },
          { columnName: 'partnerMargin', direction: 'desc' },
        ]}
      />
      <SelectionState selection={selectedRowsIndexes} onSelectionChange={setSelectedRowsIndexes} />
      <IntegratedSelection />
      <RowDetailState expandedRowIds={expandedRowIds} onExpandedRowIdsChange={(n) => setExpandedRowIds(n)} />
      <DataTypeProvider
        for={columnsToApplyCurrencyFormatting}
        formatterComponent={CurrencyNumberWithTooltipFormatter}
      />
      <TableWrapper
        virtual
        noDataCellComponent={CustomNoDataComponent}
        rowComponent={(props) => (
          <NewTableRow expandedRowIds={expandedRowIds} setExpandedRowIds={setExpandedRowIds} {...props} />
        )}
        height="auto"
      />
      <TableSelection showSelectAll cellComponent={CustomSelectionCell} />
      <TableHeaderRow showSortingControls cellComponent={CustomHeaderCell} />
      <TableRowDetail
        contentComponent={(d) => <CustomerMargins row={d.row} timeRange={timeRange} />}
        toggleCellComponent={CustomToggleCell}
      />
      <NewTableSelectionBar selectedRows={selectedRowsIndexes} counterClass="">
        <div className={classes.bulkRow}>
          <CSVLink data={prepareCsvModifiedRows(selectedRows)} headers={null} filename="billing-summary-selected.csv">
            <GenerateIcon iconName={ICONS.download.name} /> Download Summary to CSV
          </CSVLink>
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => exportRows({ rows: prepareServerExportRows(selectedRows), type: 'csv' })}
          >
            <GenerateIcon iconName={ICONS.download.name} /> Download Invoices to CSV
          </div>
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => exportRows({ rows: prepareServerExportRows(selectedRows), type: 'pdf' })}
          >
            <GenerateIcon iconName={ICONS.download.name} /> Download Invoices to PDF
          </div>
        </div>
      </NewTableSelectionBar>
    </Grid>
  );
};

export default CustomersHistoryTable;
