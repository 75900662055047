import React from 'react';
import { Search } from '../../Search';
import { PERMISSION_ENTITIES } from '../../../consts';

import styles from './SharedEntitiesModal.module.scss';

const SharedEntitiesModalHeader = ({ search, setSearch, category }) => (
  <div className={styles.headerContainer}>
    <div className={styles.headerLeft}>
      <div className={styles.header}>
        <div className={styles.title}>{`Shared ${PERMISSION_ENTITIES[category].title}`}</div>
      </div>
    </div>
    <div className={styles.headerRight}>
      <Search search={search} setSearch={setSearch} />
    </div>
  </div>
);

export default SharedEntitiesModalHeader;
