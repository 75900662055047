import React, { useState } from 'react';
import { GenerateIcon, ICONS, Navbar, BIZ_PACK_OPTIONS } from '@pileus-cloud/anodot-frontend-common/dist';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import AppLogo from 'app/containers/Layout/topNavbar/components/AppLogo';
import ScopePicker from 'app/containers/Layout/topNavbar/components/ScopePicker';
import { CLOUD_TYPE_IDS, USER_TYPES_VALS, /* ACCOUNT_FEATURES, */ UsersType } from 'users/constants/usersConstants';
import { Routes } from 'shared/constants/routes';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import MarginPicker from 'app/containers/Layout/topNavbar/components/MarginPicker';
import UserSettings from 'app/containers/Layout/topNavbar/components/UserSettings';
import AccountPicker from 'app/containers/Layout/topNavbar/components/AccountPicker';
import Spinner, { SIZES } from 'shared/components/andtComponents/Spinner';
import HolidayUnderHeaderRightIcon from 'shared/components/HolidayThemedIcons/HolidayUnderHeaderRightIcon';
import { DEFAULT_THEME } from 'app/containers/App/components/CustomThemeApplier';
import classes from 'app/containers/Layout/topNavbar/TopNavBar.module.scss';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import Notifications from 'usage/containers/Notifications/Notifications';
import { useNotifications } from 'usage/hooks/react-query/useNotifications';
import { CURRENCY_DETAILS } from 'shared/constants/appConstants';
import useDebouncedInput from 'shared/hooks/customHooks/useDebouncedInput';
import { useCostCenters } from 'users/new-user-management/hooks/reactQuery/useCostCenters';
import { useMspCustomers } from 'users/new-user-management/hooks/reactQuery/useMspCustomers';
import { useDbFlags } from 'usage/hooks/react-query/useDbFlags';

const TopNavbar = (props) => {
  const { childProps, currDisplayedAccount, location, history } = props;
  const { usersStore, appStore, invoiceStore, usageStore, invalidateStores } = useRootStore();
  const [notificationsOpened, setNotificationsOpened] = useState(false);
  const { fetchNotifications } = useNotifications();
  const { currDispUserAccountKey } = usersStore;
  const [lastFetchedAccountKey] = useDebouncedInput(currDispUserAccountKey, 500);
  const { fetchCostCentersData } = useCostCenters();
  const { fetchMspCustomersData } = useMspCustomers();
  const { fetchDbFlags } = useDbFlags();

  const { data: notifications = [], isLoading: notificationsLoading } = fetchNotifications({
    enabled: !!usersStore.authUserKey && !!currDisplayedAccount && currDispUserAccountKey === lastFetchedAccountKey,
  });
  const { currencyCode, currencySymbol } = useUserSettingsContext();
  const { data: dbFlagsData } = fetchDbFlags({
    enabled: !!usersStore.currentDisplayedUserKey && !!usersStore.currDispUserAccountKey,
  });

  const handleChangeToCustomerView = (divId, divisionName, accountKey) => {
    usersStore.changeCustomerUserType();
    appStore.updatePricingMode(true);
    usersStore.updateCurrDisplayedDivIdAndName(divId, divisionName, accountKey, true);
    invalidateStores(true, true);
    const currentPath = location.pathname;
    history.push(currentPath);
  };

  const getScopeData = () => {
    /* TODO - when move all the application to use keycloak and accounts service,
    change this logic to support impersonation */
    if (appStore.isKeyCloakManagement) {
      const { isLoading: isCostCentersLoading, data: costCentersData } = fetchCostCentersData();
      let isMspCustomersLoading = false;
      let mspCustomersData = [];
      if ([USER_TYPES_VALS.RESELLER].includes(+usersStore.currentDisplayedUserType)) {
        const { isLoading, data } = fetchMspCustomersData();
        isMspCustomersLoading = isLoading;
        mspCustomersData = data;
      }
      return {
        isLoading: isCostCentersLoading || isMspCustomersLoading,
        data: [...(costCentersData || []), ...(mspCustomersData || [])],
      };
    }
    const { divisionList } = usersStore.usersModel;
    return { data: divisionList };
  };

  const renderTrialDaysText = ({ trialDaysLeft, isKeyCloakManagement, currDispUserPricingPlanId, userEndTrial }) => {
    const trialDaysText =
      trialDaysLeft !== 0 ? `Evaluation expires in ${trialDaysLeft} days` : 'Your free trial has expired';
    if ((isKeyCloakManagement && userEndTrial) || (!isKeyCloakManagement && currDispUserPricingPlanId === 2)) {
      return <div className={classes.menuItem}>{trialDaysText}</div>;
    }
    return null;
  };

  const RenderNavbar = () => {
    const { currentDisplayedUserType, currDispUserAccountKey, currDispUserCloudAccountType, trialDaysLeft } =
      usersStore;
    const accounts = usersStore.getAllCurrDisplayedUserAccounts();
    const { isLoading: isScopeLoading, data: scopeListData } = getScopeData();
    const cloudTypeId = usersStore.currDispUserCloudAccountType;
    const marginPickerClass = { ...classes, item: classes.marginPickerItem, tooltip: classes.tooltip };
    const isUserOnboarded = usersStore.usersModel.users.length !== 0 && currentDisplayedUserType !== UsersType.NEW_USER;
    return (
      <Navbar
        logo={<AppLogo />}
        value={
          !usersStore.currentHoliday && usersStore.getCurrDisplayedUserTheme() === DEFAULT_THEME
            ? BIZ_PACK_OPTIONS.cloudCost.value
            : ''
        }
        onClick={() => {
          history.push(currDisplayedAccount ? Routes.DASHBOARD : Routes.ACCOUNTS_OVERVIEW);
        }}
        tooltip={`App version: ${process.env.REACT_APP_VERSION}-${dbFlagsData?.isBigQueryRead ? 'BQ' : 'PG'}`}
      >
        <div className={classes.topbarDropdowns}>
          {appStore.isAccountLoading ? (
            <div style={{ position: 'relative', width: '50px', height: '100%' }}>
              <Spinner size={SIZES.SMALL_30} />
            </div>
          ) : null}
          {renderTrialDaysText({
            trialDaysLeft,
            isKeyCloakManagement: appStore.isKeyCloakManagement,
            currDispUserPricingPlanId: usersStore.currDispUserPricingPlanId,
            userEndTrial: usersStore.userEndTrial,
          })}
          {scopeListData?.length && currDisplayedAccount && cloudTypeId !== CLOUD_TYPE_IDS.MULTI ? (
            <div className={classes.menuItem}>
              {isScopeLoading ? (
                <Spinner size={20} color="white" />
              ) : (
                <ScopePicker
                  classes={classes}
                  handleBackToCurrDisplayedAccount={usersStore.handleBackToCurrDisplayedAccount}
                  currentDisplayedCustomer={usersStore.currDispUserDivisionName}
                  currentDisplayedCloudTypeId={usersStore.currDispUserCloudAccountType}
                  accountsList={[currDisplayedAccount]}
                  history={history}
                  divisionsList={scopeListData?.map(
                    ({ divisionName, divisionNameDisplay, name, divisionId, id, ...rest }) => ({
                      ...rest,
                      divisionName: name || divisionName,
                      divisionNameDisplay: name || divisionNameDisplay || divisionName,
                      divisionId: id || divisionId,
                    }),
                  )}
                  handleChangeCustomer={handleChangeToCustomerView}
                  currAccountDivisionId={usersStore.getCurrAccountDivisionId()}
                  currDispUserDivisionId={usersStore.currDispUserDivisionId}
                  isAccountLoading={appStore.isAccountLoading}
                />
              )}
            </div>
          ) : null}
          {usersStore.isCurrentUserReseller ? (
            <div className={classes.menuItem}>
              <MarginPicker classes={marginPickerClass} />
            </div>
          ) : null}
          {isUserOnboarded && accounts?.length > 0 ? (
            <div className={classes.menuItem}>
              <AccountPicker
                classes={classes}
                handleAccountChange={usersStore.handleDisplayedAccountChange}
                handleChangeCustomer={handleChangeToCustomerView}
                currentDisplayedAccount={usersStore.getCurrentDisplayedAccountName(currDispUserAccountKey)}
                currentDisplayedCloudTypeId={currDispUserCloudAccountType}
                currentDisplayedUserType={currentDisplayedUserType}
                accountsList={accounts}
                history={history}
              />
            </div>
          ) : null}
        </div>
        <div className={classes.divider} />
        {isUserOnboarded && currDisplayedAccount ? (
          <div className={classes.roundButton}>
            {currencyCode || CURRENCY_DETAILS.USD} ({currencySymbol})
          </div>
        ) : null}
        {currDisplayedAccount && (
          <div
            className={`${classes.roundButton} ${classes.notificationsButton}`}
            onClick={() => setNotificationsOpened(true)}
          >
            <div
              className={classes.notificationsCounter}
              data-empty={notifications?.length === 0}
              data-loading={notificationsLoading}
            >
              {notificationsLoading ? <Spinner size={20} color="white" /> : <GenerateIcon iconName={ICONS.bell.name} />}
            </div>
            <span>{notificationsLoading ? '-' : notifications?.length}</span>
          </div>
        )}
        {notificationsOpened && (
          <Notifications notifications={notifications} onClose={() => setNotificationsOpened(false)} />
        )}
        <Tooltip title="Account settings">
          <div
            className={`${classes.roundButton} ${classes.settingsButton}`}
            onClick={() => {
              history.push({
                pathname: Routes.ACCOUNT,
              });
            }}
          >
            <GenerateIcon iconName={ICONS.gearRegular.name} />
          </div>
        </Tooltip>
        {usersStore.usersModel.users.length !== 0 ? (
          <UserSettings
            classes={classes}
            currDisplayedAccount={currDisplayedAccount}
            handleLogout={childProps.handleLogout}
            history={history}
            invoiceStoreLoad={invoiceStore.isLoading}
            usageStoreLoad={usageStore.isLoading}
          />
        ) : null}
        {usersStore.getCurrDisplayedUserTheme() ? (
          <HolidayUnderHeaderRightIcon holiday={usersStore.currentHoliday} />
        ) : null}
      </Navbar>
    );
  };

  return RenderNavbar();
};

TopNavbar.propTypes = {
  childProps: PropTypes.object.isRequired,
  currDisplayedAccount: PropTypes.object,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};
TopNavbar.defaultProps = {
  currDisplayedAccount: null,
};

const TopbarObserver = observer(TopNavbar);
export default TopbarObserver;
