import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  GenerateIcon,
  ICONS,
  Popover,
  PopoverContent,
  PopoverPortal,
  PopoverTrigger,
} from '@pileus-cloud/anodot-frontend-common/dist';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import Input from 'shared/components/andtComponents/Input';
import checkFeatureFlag from 'shared/utils/featureFlagUtil';
import { ACCOUNT_FEATURES } from 'users/constants/usersConstants';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { useRecommendationsNewContext } from 'recommendationsNew/contexts/recommendationsNewContext';
import useRecViews from 'recommendationsNew/hooks/react-query/useRecViews';
import { useHeatMapContext } from '../contexts/heatMapContext';
import HeatMapActionsContext from '../contexts/heatMapActionsContext';
import { getHistoryFiltersViewStateCopy, getInitialFiltersStateCopy } from '../heatMapFilters/filterStates';
import classes from './heatMapActions.module.scss';
import { ViewActions } from './viewActions';

const VIEW_NAMES = { MainView: 'Main View', SavingTracker: 'Saving Tracker (History)' };

const CreateOrEditViewName = ({ viewName, setViewName, saveView, cancelView, isValid }) => (
  <div className={classes.saveViewWrapper}>
    <div className={classes.inputWrapper}>
      <Input
        className={classes.viewInput}
        value={viewName}
        onChange={(e) => {
          setViewName(e.target.value);
        }}
      />
      {!isValid && <span className={classes.error}>Name already exists</span>}
    </div>
    <GenerateIcon iconName={ICONS.check.name} className={classes.checkIcon} onClick={saveView} />
    <div className={classes.cancel} onClick={cancelView}>
      X
    </div>
  </div>
);

const ViewsPanel = () => {
  const { usersStore } = useRootStore();
  const { setIsSavingsTracker } = useContext(HeatMapActionsContext);
  const recommendationsNewContext = useRecommendationsNewContext();
  const heatMapContext = useHeatMapContext();
  const { recommendationFilters, setRecommendationFilters } = recommendationsNewContext;
  const { isWasteChart, setIsWasteChart, heatMapGroupByOptions } = heatMapContext;
  const { fetchRecViews, useCreateViewPanel, useUpdateViewPanel } = useRecViews(
    checkFeatureFlag(usersStore, ACCOUNT_FEATURES.RECOMMENDATION_VIEWS),
  );
  const { data: views, isLoading } = fetchRecViews();
  const { mutate: createView } = useCreateViewPanel();
  const { mutate: updateView } = useUpdateViewPanel();
  const [isOpen, setIsOpen] = useState(false);
  const [isCreateMode, setIsCreateMode] = useState(false);
  const [invalidName, setInvalidName] = useState(false);
  const [creatViewName, setCreatViewName] = useState('');
  const [allViews, setAllViews] = useState(
    Object.values(VIEW_NAMES).map((view) => ({
      view_name: view,
      is_default: false,
    })),
  );
  const [selectedView, setSelectedView] = useState(VIEW_NAMES.MainView);
  const [changeViewNameInput, setChangeViewNameInput] = useState(null);

  const isViewNameExist = (name) => allViews.some((view) => view.view_name === name);
  const updateContext = (view) => {
    // set dynamic context data for heatMap area
    Object.keys(view.context_data).forEach((key) => {
      const setterFunctionName = `set${key.charAt(0).toUpperCase()}${key.slice(1)}`;
      if (typeof recommendationsNewContext[setterFunctionName] === 'function' && view.context_data[key] !== undefined) {
        recommendationsNewContext[setterFunctionName](view.context_data[key]);
      } else if (typeof heatMapContext[setterFunctionName] === 'function' && view.context_data[key] !== undefined) {
        heatMapContext[setterFunctionName](view.context_data[key]);
      }
    });
  };

  useEffect(() => {
    if (!views?.length) {
      if (!isLoading) {
        setSelectedView(VIEW_NAMES.MainView);
        setAllViews(
          Object.values(VIEW_NAMES).map((view) => ({
            view_name: view,
            is_default: view === VIEW_NAMES.MainView,
          })),
        );
      }
      return;
    }

    const defaultView = views.find((view) => view.is_default);
    const initialViews = Object.values(VIEW_NAMES).map((view) => ({
      view_name: view,
      is_default: !defaultView && view === VIEW_NAMES.MainView,
    }));

    const mergedViews = [...initialViews, ...views];

    setAllViews(mergedViews);
    // set new view to select after create mode
    const newView = mergedViews.find((view) => view.view_name === creatViewName);
    if (newView) {
      setSelectedView(newView.id);
      setCreatViewName('');
      return;
    }
    // load last selected view after render data
    const selectView = views.find((view) => view.id === selectedView);
    if (selectView) {
      updateContext(selectView);
      return;
    }
    // load default view/ main view for first loading
    if (defaultView) {
      setSelectedView(defaultView?.id);
      updateContext(defaultView);
    } else {
      setSelectedView(VIEW_NAMES.MainView);
      setRecommendationFilters(getInitialFiltersStateCopy());
    }
  }, [views]);

  const isNameValidAndUnique = useMemo(
    () => !invalidName && !isViewNameExist(creatViewName),
    [invalidName, creatViewName],
  );
  const optionSelected = (option) => () => {
    const selectedItem = allViews.find((view) => view.view_name === option);
    if (option === VIEW_NAMES.MainView) {
      setRecommendationFilters(getInitialFiltersStateCopy());
      setIsWasteChart(false);
      setIsSavingsTracker(false);
    } else if (option === VIEW_NAMES.SavingTracker) {
      if (!isWasteChart) {
        setRecommendationFilters(getHistoryFiltersViewStateCopy());
        setIsSavingsTracker(true);
      }
    } else if (selectedItem) {
      updateContext(selectedItem);
      setIsSavingsTracker(false);
    }
    setSelectedView(selectedItem?.id || option);
  };

  const createNewView = () => {
    if (isViewNameExist(creatViewName)) {
      setInvalidName(true);
      return;
    }
    setInvalidName(false);
    setIsCreateMode(false);
    createView({
      viewName: creatViewName,
      contextData: { recommendationFilters, isWasteChart, heatMapGroupByOptions },
      isDefault: false,
    });
  };

  return (
    <Popover open={isOpen} onOpenChange={() => setIsOpen(!isOpen)}>
      <PopoverTrigger>
        <Tooltip title="Select View">
          <div className={classes.actionsPanelButton} automation-id="heatmap-select-view">
            <GenerateIcon iconName={ICONS.views.name} />
          </div>
        </Tooltip>
      </PopoverTrigger>

      <PopoverPortal>
        <PopoverContent align="bottom left" sideOffset={7} className={classes.popoverContent}>
          {allViews.map((view) => (
            <div
              className={`${classes.dropDownViewRow} ${
                isWasteChart && view.view_name === VIEW_NAMES.SavingTracker ? classes.disabledOption : ''
              }`}
              onClick={optionSelected(view.view_name)}
            >
              {changeViewNameInput && changeViewNameInput.id === view.id ? (
                <CreateOrEditViewName
                  viewName={changeViewNameInput.name}
                  setViewName={(val) => setChangeViewNameInput({ ...changeViewNameInput, name: val })}
                  saveView={() => {
                    if (isViewNameExist(changeViewNameInput.name)) {
                      setInvalidName(true);
                      return;
                    }
                    const updatedView = { ...view, view_name: changeViewNameInput.name };
                    updateView(updatedView);
                    setAllViews((prevViews) => prevViews.map((v) => (v.id === updatedView.id ? updatedView : v)));
                    setChangeViewNameInput(null);
                    setIsOpen(false);
                  }}
                  cancelView={() => {
                    setChangeViewNameInput(null);
                  }}
                  isValid={isNameValidAndUnique}
                />
              ) : (
                <>
                  <div className={classes.viewName}>
                    <>
                      {view.view_name} {view.is_default && <span className={classes.defaultView}>default</span>}
                    </>
                  </div>
                  <GenerateIcon
                    iconName={ICONS.eye.name}
                    className={`${classes.viewIcon} ${
                      ![view.view_name, view.id].includes(selectedView) ? classes.viewIconNotSelected : ''
                    }`}
                  />
                  <div className={classes.actionWrapper}>
                    {view.id === selectedView && (
                      <ViewActions view={view} changeViewName={setChangeViewNameInput} setIsOpen={setIsOpen} />
                    )}
                  </div>
                </>
              )}
            </div>
          ))}
          {checkFeatureFlag(usersStore, ACCOUNT_FEATURES.RECOMMENDATION_VIEWS) && (
            <div className={classes.createWrapper}>
              {!isCreateMode ? (
                <div onClick={() => setIsCreateMode(!isCreateMode)}>
                  <GenerateIcon iconName={ICONS.plus.name} className={classes.plusIcon} />
                  <span>Creat View</span>
                </div>
              ) : (
                <CreateOrEditViewName
                  viewName={creatViewName}
                  setViewName={setCreatViewName}
                  saveView={createNewView}
                  cancelView={() => {
                    setCreatViewName('');
                    setIsCreateMode(false);
                  }}
                  isValid={isNameValidAndUnique}
                />
              )}
            </div>
          )}
        </PopoverContent>
      </PopoverPortal>
    </Popover>
  );
};

export default ViewsPanel;
