import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  Grid,
  TableColumnResizing,
  TableFilterRow,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
} from '@devexpress/dx-react-grid-material-ui';
import {
  DataTypeProvider,
  FilteringState,
  IntegratedSelection,
  RowDetailState,
  SelectionState,
} from '@devexpress/dx-react-grid';
import useTable from 'shared/hooks/customHooks/useTable';
import TableWrapper from 'shared/components/tables/TableWrapper';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import {
  FILTER_NUMBER_OR_DATE_OPERATIONS,
  FILTER_OPERATIONS,
} from 'shared/components/andtComponents/TableComponents/const';
import {
  CustomFilterCell,
  CustomFilterIcon,
  CustomFilterRow,
  CustomFormatterWithTooltip,
  CustomFormatterWithTooltipAndComponent,
  CustomHeaderCell,
  CustomSelectionCell,
  CustomToggleCell,
} from 'shared/components/andtComponents/TableComponents/TableComponents';
import { ReactComponent as NoResults } from './img/no-results.svg';
import { getColumns, ROLES_COLUMNS } from '../consts';
import styles from './RolesTable.module.scss';
import RolesIcon from './RolesIcon';
import RoleIdCopy from './rolesTableComponents/RoleIdCopy';
import AddUsersToRole from './rolesTableComponents/AddUsersToRole';
import RoleActions from './RoleActions';
import RoleExpandedData from './rolesExpandedRowComponents/RoleExpandedData';
import AddLinkedAccountsToRole from './rolesTableComponents/AddLinkedAccountsToRole';
import RolesBulkOperations from './RolesBulkOperations';
import { useRolesContext } from './contexts/rolesContext';

export const RolesNoData = (roles) => {
  if (!roles) {
    return null;
  }
  return (
    <div className={styles.noResultsWrapper}>
      <NoResults />
      <span className={styles.mainText}>No Roles were found</span>
      <span className={styles.subText}>Try different filters</span>
    </div>
  );
};

const RolesTable = ({ roles }) => {
  const [localColumnsWidth, setLocalColumnsWidth] = useState(
    Object.values(ROLES_COLUMNS).map((c) => ({ columnName: c.columnName, width: c.width })),
  );

  const rightAlignedColumns = [ROLES_COLUMNS.ADD_USERS.columnName, ROLES_COLUMNS.ACTIONS.columnName];

  const { NewTableRow, NewTableSelectionBar } = useTable();

  const [expandedRowIds, setExpandedRowIds] = useState([]);

  const { showFilters, setSearchText, selectedRows, setSelectedRows, isAllExpanded, setIsAllExpanded } =
    useRolesContext();

  const updateFilters = (filters) => {
    const tempFilters = {};
    filters.forEach((f) => {
      tempFilters[f.columnName] = { like: [f.value] };
    });
    setSearchText(tempFilters);
  };

  const debouncedSetFilters = debounce(updateFilters, 500);

  const onFiltersChange = (newFilters) => {
    debouncedSetFilters(newFilters);
  };

  useEffect(() => {
    if (!isAllExpanded) {
      setExpandedRowIds([]);
    } else {
      setExpandedRowIds(roles?.map((row, index) => index));
    }
  }, [isAllExpanded]);

  useEffect(() => {
    if (expandedRowIds?.length === 0) {
      setIsAllExpanded(false);
    }
  }, [expandedRowIds]);

  return (
    roles && (
      <Grid rows={roles} columns={getColumns(ROLES_COLUMNS)} automationId="roles-list">
        {showFilters && <FilteringState onFiltersChange={onFiltersChange} />}
        <SelectionState selection={selectedRows} onSelectionChange={setSelectedRows} />
        <IntegratedSelection />
        <RowDetailState expandedRowIds={expandedRowIds} onExpandedRowIdsChange={setExpandedRowIds} />
        <DataTypeProvider
          for={[ROLES_COLUMNS.ROLE_NAME.columnName]}
          formatterComponent={(props) => (
            <CustomFormatterWithTooltipAndComponent {...props} isLeftComponent>
              <RolesIcon />
            </CustomFormatterWithTooltipAndComponent>
          )}
          availableFilterOperations={[FILTER_OPERATIONS.CONTAINS]}
        />
        <DataTypeProvider
          for={[ROLES_COLUMNS.ROLE_ID.columnName]}
          formatterComponent={(props) => (
            <CustomFormatterWithTooltipAndComponent {...props} isLeftComponent={false}>
              <RoleIdCopy roleId={props?.identifier?.id} />
            </CustomFormatterWithTooltipAndComponent>
          )}
          availableFilterOperations={[FILTER_OPERATIONS.CONTAINS]}
        />
        <DataTypeProvider
          for={[ROLES_COLUMNS.USERS_COUNT.columnName]}
          formatterComponent={CustomFormatterWithTooltip}
          availableFilterOperations={FILTER_NUMBER_OR_DATE_OPERATIONS}
        />
        <DataTypeProvider
          for={[ROLES_COLUMNS.CREATED_AT.columnName]}
          formatterComponent={(props) => (
            <CustomFormatterWithTooltip value={moment(props.createdAt).format('MMM DD YYYY, hh:mm:ss A')} isSmallText />
          )}
          availableFilterOperations={FILTER_NUMBER_OR_DATE_OPERATIONS}
        />
        <DataTypeProvider
          for={[ROLES_COLUMNS.CREATED_BY.columnName]}
          formatterComponent={CustomFormatterWithTooltip}
          availableFilterOperations={FILTER_NUMBER_OR_DATE_OPERATIONS}
        />
        <DataTypeProvider
          for={[ROLES_COLUMNS.UPDATED_AT.columnName]}
          formatterComponent={(props) => (
            <CustomFormatterWithTooltip value={moment(props.lastLogin).format('MMM DD YYYY, hh:mm:ss A')} isSmallText />
          )}
          availableFilterOperations={FILTER_NUMBER_OR_DATE_OPERATIONS}
        />
        <DataTypeProvider
          for={[ROLES_COLUMNS.UPDATED_BY.columnName]}
          formatterComponent={CustomFormatterWithTooltip}
          availableFilterOperations={FILTER_NUMBER_OR_DATE_OPERATIONS}
        />
        <DataTypeProvider
          for={[ROLES_COLUMNS.ADD_LINKED_ACCOUNTS.columnName]}
          formatterComponent={AddLinkedAccountsToRole}
        />
        <DataTypeProvider for={[ROLES_COLUMNS.ADD_USERS.columnName]} formatterComponent={AddUsersToRole} />
        <DataTypeProvider for={[ROLES_COLUMNS.ACTIONS.columnName]} formatterComponent={RoleActions} />
        <TableWrapper
          virtual
          noDataCellComponent={() => RolesNoData(roles)}
          rowComponent={(props) => (
            <NewTableRow expandedRowIds={expandedRowIds} setExpandedRowIds={setExpandedRowIds} {...props} />
          )}
          height="auto"
        />
        <TableSelection showSelectAll cellComponent={CustomSelectionCell} />
        <TableColumnResizing
          resizingMode="nextColumn"
          defaultColumnWidths={localColumnsWidth}
          onColumnWidthsChange={setLocalColumnsWidth}
        />
        <TableRowDetail contentComponent={RoleExpandedData} toggleCellComponent={CustomToggleCell} />
        <TableHeaderRow cellComponent={CustomHeaderCell} />
        {showFilters && (
          <TableFilterRow
            showFilterSelector
            iconComponent={CustomFilterIcon}
            rowComponent={CustomFilterRow}
            cellComponent={(props) => <CustomFilterCell {...props} actionColumns={rightAlignedColumns} />}
          />
        )}
        <NewTableSelectionBar selectedRows={selectedRows} counterClass={styles.counterClass}>
          <RolesBulkOperations roles={roles} />
        </NewTableSelectionBar>
      </Grid>
    )
  );
};

RolesTable.propTypes = {
  roles: PropTypes.array.isRequired,
};

export default RolesTable;
