import ApiGateway from 'shared/modules/apiGateway';
import CommonApi from 'shared/api/commonApi';
import InvoicesApi from 'invoices/api/invoicesApi';
import UsageApi from 'usage/api/usageApi';
import { UsersApi } from 'users/api/usersApi';
import InvoiceStore from 'invoices/store/invoiceStore';
import UsageStore from 'usage/store/usageStore';
import CommitmentStore from 'commitment/store/commitmentStore';
import UsersStore from 'users/store/usersStore';
import KubernetesStore from 'kubernetes/store/kubernetesStore';
import { ModelNames } from 'shared/constants/appConstants';
import integrationsUtils from 'shared/utils/integrationsUtils';
import { runInAction } from 'mobx';
import apiConstants from 'shared/api/apiConstants';
import { reportsProvider } from 'usage/hooks/react-query/reportsProvider';
import AppStore from './appStore';

// RootStore that instantiates all stores, and share references
export default class RootStore {
  constructor(modelsMap, queryClient) {
    // APIs
    this.queryClient = queryClient;
    this.fetchingData = false;
    this.commonApi = new CommonApi();
    this.invoicesApi = new InvoicesApi();
    this.usersAPI = new UsersApi();
    this.usageApi = new UsageApi();
    this.apiGateway = new ApiGateway(this.commonApi, this.invoicesApi, this.usersAPI, this.usageApi, null);
    // Stores
    this.usersStore = new UsersStore(this, modelsMap.get(ModelNames.USERS_MODEL));
    this.invoiceStore = new InvoiceStore(this, this.apiGateway);
    this.kubernetesStore = new KubernetesStore(this, this.apiGateway, modelsMap.get(ModelNames.K8S_USAGE_MODEL));
    this.appStore = new AppStore(this, this.apiGateway);
    this.usageStore = new UsageStore(
      this,
      this.apiGateway,
      modelsMap.get(ModelNames.COMMITMENT_MODEL),
      modelsMap.get(ModelNames.CUE_ALERTS_MODEL),
      modelsMap.get(ModelNames.K8S_USAGE_MODEL),
      modelsMap.get(ModelNames.CUSTOM_DASHBOARD_MODEL),
    );
    this.commitmentStore = new CommitmentStore(this, modelsMap.get(ModelNames.COMMITMENT_MODEL));
    this.reportsProvider = reportsProvider();
  }

  fetchData = async (currDispUserKey, fetchDivisions = true) => {
    if (!currDispUserKey) {
      return;
    }
    const isIntegrationCompleted = await integrationsUtils.completeAuthForIntegrations();
    if (isIntegrationCompleted) {
      return;
    }
    await this.usersStore.fetchData(currDispUserKey);
    const isFetchRequired = this.usersStore.isFetchRequired();
    if (!isFetchRequired) {
      this.appStore.setIsAccountLoading(false);
      return;
    }
    const fetchArray = [this.appStore.fetchData, this.usageStore.fetchData];
    if (fetchDivisions) {
      fetchArray.push(async () => this.usersStore.fetchDivisionGroups());
    }
    // TODO: this is preventing divisions API call when switching accounts very quickly
    // (before previous assignment fetchingData to null). Need to find if it's necessary.
    if (this.fetchingData === currDispUserKey) {
      return;
    }
    try {
      this.fetchingData = currDispUserKey;
      this.appStore.setIsAccountLoading(true);
      this.usageStore.customDbSubStore.fetchDashboardsAndPanels();
      await Promise.all(fetchArray.map((fn) => fn(currDispUserKey, fetchDivisions)));
      this.usersStore.currDispUserDivisionName = this.usersStore.getDivisionNameByDivId(
        this.usersStore.currDispUserDivisionId,
      );
      await this.reportsProvider.fetchReports(currDispUserKey, this.usersStore.currDispUserAccountKey);
      this.appStore.setIsAccountLoading(false);
    } catch (error) {
      this.appStore.setIsAccountLoading(false);
    } finally {
      this.fetchingData = null;
    }
  };

  invalidateStores = (isCustomDashboardsExcludedFromReset = true, isScopeSwitch = false) => {
    if (this.queryClient) {
      const excludeResetQueryKeysArray = isCustomDashboardsExcludedFromReset
        ? [
            apiConstants.QUERY_KEYS.CUSTOM_DASHBOARD_DASHBOARDS,
            apiConstants.QUERY_KEYS.CUSTOM_DASHBOARD_TEMPLATES,
            apiConstants.QUERY_KEYS.CUSTOM_DASHBOARD_PANELS,
          ]
        : [];
      if (isScopeSwitch) {
        excludeResetQueryKeysArray.push(
          apiConstants.QUERY_KEYS.FILTERS_DISTINCT_VALUES,
          apiConstants.QUERY_KEYS.FILTERS_DISTINCT_K8S_VALUES,
          apiConstants.QUERY_KEYS.FILTERS_DISTINCT_TAGS_VALUES,
        );
      }
      this.queryClient.removeQueries({
        predicate: (query) => excludeResetQueryKeysArray?.indexOf(query.queryKey[0]) === -1,
      });
    }

    runInAction(() => {
      this.invoiceStore.invalidateStore();
      this.usageStore.invalidateStore();
      this.commitmentStore.invalidateStore();
      this.kubernetesStore.invalidateStore();
    });
  };
  invalidateStoresLogOut = () => {
    runInAction(() => {
      this.usersStore.invalidateStore();
      this.appStore.invalidateStore();
      this.invoiceStore.invalidateStore();
      this.usageStore.invalidateStore();
      this.usersStore.invalidateDivisionGroups();
      this.usersStore.invalidateSubUsers();
      this.commitmentStore.invalidateStore();
      this.kubernetesStore.invalidateStore();
    });
  };
}
