import React, { useState } from 'react';
import { Popover, PopoverContent, PopoverTrigger } from '@pileus-cloud/anodot-frontend-common/dist';

import styles from './UserRoles.module.scss';

const UserRoles = ({ row }) => {
  const { roles } = row;
  const [isOpen, setIsOpen] = useState(false);

  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  return (
    <div className={styles.userRoles} onClick={(e) => e.stopPropagation()}>
      {roles[0]?.displayName}
      <Popover open={isOpen}>
        <PopoverTrigger as="button" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          {roles.length > 1 && <span className={styles.moreRoles}>+{roles.length - 1}</span>}
        </PopoverTrigger>
        <PopoverContent
          className={styles.popoverContent}
          side="bottom"
          onMouseEnter={() => setIsOpen(true)}
          onMouseLeave={() => setIsOpen(false)}
        >
          {roles?.map((role) => (
            <div key={role.id} className={styles.role}>
              {role.displayName}
            </div>
          ))}
        </PopoverContent>
      </Popover>
    </div>
  );
};

export default UserRoles;
