import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  IntegratedSorting,
  DataTypeProvider,
  SortingState,
  PagingState,
  IntegratedPaging,
  SelectionState,
  IntegratedSelection,
} from '@devexpress/dx-react-grid';
import { ICONS, GenerateIcon } from '@pileus-cloud/anodot-frontend-common/dist';
import {
  Grid,
  TableHeaderRow,
  PagingPanel,
  TableColumnResizing,
  TableSelection,
} from '@devexpress/dx-react-grid-material-ui';
import ReadOnlyDisplayWrapper from 'shared/components/ReadOnlyDisplayWrapper';
import { useRootStore } from 'app/contexts/RootStoreContext';
import DeleteWarningModal from 'shared/components/DeleteWarningModal';
import TableWrapper from 'shared/components/tables/TableWrapper';
import useTable from 'shared/hooks/customHooks/useTable';
import ButtonDropdown from 'shared/components/andtComponents/ButtonDropdown';
import Button from 'shared/components/andtComponents/Button';
import { withRolesContextConsumer } from 'users/contexts/RolesProvider';
import toast from 'shared/components/andtComponents/Toast';
import {
  USERS_COLUMN_EXTENSIONS,
  USERS_TABLE_COLUMNS,
} from 'users/containers/RolesAndUsers/constants/rolesAndUsersConstants';
import {
  useLinkedAccountsTableState,
  useUsersTableState,
} from 'users/containers/RolesAndUsers/contexts/rolesAndUsersContext';
import { ReactComponent as EmptyUserIcon } from 'shared/img/images/empty-user.svg';
import styles from './UsersTable.module.scss';
import AddOrEditUserModal from './AddOrEditUserModal';
import PermissionIcon from './PermissionIcon';

const UsersTable = ({ users, roleId, roles, addUserHandler }) => {
  const [columnWidths, setColumnWidths] = useState(USERS_COLUMN_EXTENSIONS);
  const [deletedUsers, setDeletedUsers] = useState([]);
  const [editedUsers, setEditedUsers] = useState([]);
  const { NewTableSelectionBar, NewNoDataCell } = useTable();
  const { usersStore, appStore } = useRootStore();
  const { anySelected: linkedAccountsChecked } = useLinkedAccountsTableState(roleId);
  const { selectedRows, setSelectedRows, page, setPage, size, setSize } = useUsersTableState(roleId);
  const renderNoDataCell = (props) => (
    <NewNoDataCell {...props}>
      <div className={styles.emptyCell}>
        <EmptyUserIcon />
        <p>Would you like to add Users to this Role?</p>
        <ReadOnlyDisplayWrapper isHide={false} userReadOnly={usersStore.currentUserReadOnly}>
          <Button
            text="Add Users"
            isTextButton
            icon={() => <GenerateIcon iconName={ICONS.addUser.name} />}
            onClick={addUserHandler}
          />
        </ReadOnlyDisplayWrapper>
      </div>
    </NewNoDataCell>
  );

  const actionsFormatter = (data) => (
    <ButtonDropdown
      text=""
      isTextButton
      icon={() => <GenerateIcon iconName={ICONS.verticalDots.name} />}
      automationId="useractions"
    >
      <ReadOnlyDisplayWrapper isHide={false} userReadOnly={usersStore.currentUserReadOnly}>
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
        <li onClick={() => setEditedUsers([data.row])} id="edit">
          <span>
            <GenerateIcon iconName={ICONS.penToSquare.name} /> Edit User
          </span>
        </li>
      </ReadOnlyDisplayWrapper>
      <ReadOnlyDisplayWrapper isHide={false} userReadOnly={usersStore.currentUserReadOnly}>
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
        <li onClick={() => setDeletedUsers([data.row])} id="delete">
          <span>
            <GenerateIcon iconName={ICONS.delete.name} /> Remove User
          </span>
        </li>
      </ReadOnlyDisplayWrapper>
    </ButtonDropdown>
  );

  const userDeleteWarningHandler = async (action) => {
    if (action === 'delete') {
      await usersStore.usersModel.deleteSubUsersBulk(
        deletedUsers.map(({ userKey, accountKey }) => ({ userKey, accountKey })),
        appStore.isKeyCloakManagement,
      );
      setSelectedRows([]);
    }
    setDeletedUsers([]);
  };
  const editNewUserWithRole = async ({ role, overrideReadOnly }) => {
    await usersStore.updateUserRole(
      editedUsers.map(({ userKey }) => ({ userKey, newRoleId: role.value, overrideReadOnly })),
    );
    setEditedUsers([]);
    setSelectedRows([]);
    toast.success('Users role was updated');
  };
  const permissionsFormatter = (data) => (
    <div className={styles.permissionsCell}>
      <PermissionIcon role={data.row.userRole} isReadOnlyUser={!!data.row.isReadOnly} />
    </div>
  );
  return (
    <div className={styles.container} data-disabled={!!linkedAccountsChecked}>
      <div className={styles.title}>
        Users <span>({users.length})</span>
      </div>
      <div className={`sub-table ${styles.tableWrapper}`}>
        <Grid rows={users} columns={USERS_TABLE_COLUMNS}>
          <SortingState />
          <IntegratedSorting />
          <SelectionState selection={selectedRows} onSelectionChange={(data) => setSelectedRows(data)} />
          <IntegratedSelection />
          <PagingState pageSize={size} onPageSizeChange={setSize} currentPage={page} onCurrentPageChange={setPage} />
          <IntegratedPaging />
          <DataTypeProvider for={['actions']} formatterComponent={actionsFormatter} />
          <DataTypeProvider for={['permissions']} formatterComponent={permissionsFormatter} />
          <TableWrapper noDataCellComponent={renderNoDataCell} columnExtensions={USERS_COLUMN_EXTENSIONS} />
          <TableColumnResizing
            columnWidths={columnWidths}
            onColumnWidthsChange={setColumnWidths}
            resizingMode="nextColumn"
          />
          <TableHeaderRow showSortingControls />
          <TableSelection showSelectAll />
          <PagingPanel />
          <NewTableSelectionBar selectedRows={selectedRows}>
            <div className={styles.selectedUsers}>
              <Button
                isTextButton
                text="Delete"
                icon={() => <GenerateIcon iconName={ICONS.delete.name} />}
                onClick={() => {
                  setDeletedUsers(users.filter((_, ind) => selectedRows.includes(ind)));
                }}
              />
              <Button
                isTextButton
                text="Move"
                icon={() => <GenerateIcon iconName={ICONS.turnDownRight.name} />}
                onClick={() => {
                  setEditedUsers(users.filter((_, ind) => selectedRows.includes(ind)));
                }}
              />
            </div>
          </NewTableSelectionBar>
        </Grid>
        <DeleteWarningModal
          deletedItemName={`${deletedUsers.length} users`}
          isOpen={deletedUsers.length > 0}
          handleDelete={userDeleteWarningHandler}
          warningMessage="Be advise you are about to delete "
          modalTitle="Delete Users"
        />
        {editedUsers.length > 0 && (
          <AddOrEditUserModal
            open
            onClose={() => {
              setEditedUsers([]);
            }}
            isEditMode
            email={editedUsers.length === 1 ? editedUsers[0].userName : ''}
            readOnly={editedUsers.length === 1 ? editedUsers[0].isReadOnly : false}
            role={editedUsers[0].userRole}
            onSave={editNewUserWithRole}
            roles={roles}
          />
        )}
      </div>
    </div>
  );
};

UsersTable.propTypes = {
  roleId: PropTypes.string.isRequired,
  roles: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  addUserHandler: PropTypes.func.isRequired,
};

export default withRolesContextConsumer(UsersTable);
