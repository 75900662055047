import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { FormProvider, useForm, useFormContext, useWatch } from 'react-hook-form';
import Stepper from 'shared/components/andtComponents/Stepper/Stepper';
import { ReactComponent as AZUREIcon } from 'shared/img/cloud-providers/simple/azure.svg';
import { useOnboarding } from 'app/hooks/react-query/useOnboarding';
import {
  AZURE_ACCOUNT_TYPES,
  AZURE_COST_TYPES,
  AZURE_ONBOARDING_FIELDS,
  AZURE_ONBOARDING_STEPS,
} from './AzureOnboardingConstants';
import useFormValidation from './hooks/useFormValidation';
import useFormNavigation from './hooks/useFormNavigation';
import AccountDetailsModal from './pages/AccountDetailsModal';
import LoadingGif from '../assets/page-loading.gif';
import { getPageForPausedOnboarding } from './AzureOnboardingUtils';
import Footer from '../SharedPages/Footer/Footer';
import { ONBOARDING_TYPES } from '../utils/OnboardingConstants';
import { AZURE_MCA_ONBOARDING_STEPS_CONFIG } from './AzureOnboardingStepsConstants';
import styles from './AzureOnboarding.module.scss';
import WarningsList from '../SharedPages/WarningsList/WarningsList';

const AZUREOnboarding = withRouter(({ usersStore, location, history }) => {
  const { getValues, setValue } = useFormContext();
  const onboardingType = getValues(AZURE_ONBOARDING_FIELDS.ONBOARDING_TYPE);
  const { fetchDatabaseMode } = useOnboarding();
  const { data: databaseMode, isLoading } = fetchDatabaseMode();
  const AZURE_ONBOARDING_STEPS_CONFIG = AZURE_MCA_ONBOARDING_STEPS_CONFIG;
  const screens = [
    { id: AZURE_ONBOARDING_STEPS.CREATE_APP, disabled: true },
    // TODO: enable when videos are ready onboardingType === ONBOARDING_TYPES.AUTOMATIC },
    {
      id: AZURE_ONBOARDING_STEPS.APP_REGISTRATION,
    },
    { id: AZURE_ONBOARDING_STEPS.BILLING_EXPORT },
    { id: AZURE_ONBOARDING_STEPS.VALIDATE_ACCOUNT },
    { id: AZURE_ONBOARDING_STEPS.PROCESS_DATA },
  ]
    .filter(({ disabled }) => !disabled)
    .map(({ id, subTitle, isDotted }) => ({
      id,
      subTitle,
      isDotted,
      title: AZURE_ONBOARDING_STEPS_CONFIG[id].titleShort,
    }));
  useEffect(() => {
    if (isLoading || !databaseMode) {
      return;
    }
    setValue(AZURE_ONBOARDING_FIELDS.IS_BIGQUERY, databaseMode.mode === 'bigquery');
  }, [isLoading]);
  const screen = getValues(AZURE_ONBOARDING_FIELDS.SCREEN);
  const screenIndex = screens.findIndex((scr) => scr.id === screen);
  useWatch(AZURE_ONBOARDING_FIELDS.SCREEN);
  useWatch(AZURE_ONBOARDING_FIELDS.ACCOUNT_ID);
  useWatch(AZURE_ONBOARDING_FIELDS.AZURE_ACCOUNT_TYPE);
  const { prevStep, nextStep } = useFormNavigation({ screen, location, history, screens });
  if (screen === AZURE_ONBOARDING_STEPS.ACCOUNT_TYPE) {
    return <AccountDetailsModal nextStep={nextStep} />;
  }
  const { component: Page, title, automaticTitle } = AZURE_ONBOARDING_STEPS_CONFIG[screen];
  const getTitle = () => {
    if (onboardingType === ONBOARDING_TYPES.AUTOMATIC && automaticTitle) {
      return automaticTitle;
    }
    return title;
  };
  return (
    <div>
      <div className={styles.container}>
        <Stepper activeStepIndex={screenIndex} steps={screens} />
        <div className={styles.pageScreen}>
          <div className={styles.header}>
            <div className={styles.headerContent}>
              {screenIndex + 1}. {getTitle()}
            </div>
            <div className={styles.headerLogo}>
              <AZUREIcon />
            </div>
          </div>
          <div className={styles.body}>{Page && <Page usersStore={usersStore} />}</div>
          <Footer steps={AZURE_ONBOARDING_STEPS_CONFIG} prevStep={prevStep} nextStep={nextStep} screen={screen} />
        </div>
      </div>
    </div>
  );
});

const AZUREOnboardingWrapper = withRouter(({ usersStore, location }) => {
  const isMSP = usersStore.isCurrentUserReseller;
  const DEFAULT_FORM_STATE = {
    accountId: '',
    accountName: '',
    [AZURE_ONBOARDING_FIELDS.ONBOARDING_TYPE]: ONBOARDING_TYPES.MANUAL,
    [AZURE_ONBOARDING_FIELDS.ACCOUNT_TYPE]: isMSP ? 'dedicated' : null,
    [AZURE_ONBOARDING_FIELDS.RESELLER_CUSTOMER]: '',
    [AZURE_ONBOARDING_FIELDS.IS_MSP]: isMSP,
    [AZURE_ONBOARDING_FIELDS.USER_KEY]: usersStore.currentDisplayedUserKey,
    [AZURE_ONBOARDING_FIELDS.ACCOUNT_KEY]: usersStore.currDispUserAccountKey,
    screen: AZURE_ONBOARDING_STEPS.ACCOUNT_TYPE,
    [AZURE_ONBOARDING_FIELDS.AZURE_ACCOUNT_TYPE]: AZURE_ACCOUNT_TYPES.MCA_EXPORT,
    [AZURE_ONBOARDING_FIELDS.AZURE_COST_TYPE]: AZURE_COST_TYPES.UNBLENDED,
  };

  const searchParams = new URLSearchParams(location.search);
  const accountId = searchParams.get('accountId');
  const screen = searchParams.get('screen');
  const { fetchOnboardingStatus } = useOnboarding();
  const methods = useForm({
    defaultValues: {
      ...DEFAULT_FORM_STATE,
      [AZURE_ONBOARDING_FIELDS.SCREEN]: screen || DEFAULT_FORM_STATE.screen,
    },
    resolver: (currentStream) => useFormValidation(currentStream),
    shouldFocusError: true,
  });
  const accountIdOnboarding = accountId || methods.getValues(AZURE_ONBOARDING_FIELDS.ACCOUNT_ID);
  const { data: onboarding, isLoading } = fetchOnboardingStatus(accountIdOnboarding, {
    enabled: !!accountIdOnboarding,
    refetchInterval: 15 * 1000,
  });
  const [isLoadingDebounced, setIsLoadingDebounced] = useState(!!accountId);
  useEffect(() => {
    if (isLoading) {
      setIsLoadingDebounced(true);
    } else {
      setTimeout(() => setIsLoadingDebounced(false), 1000);
    }
  }, [isLoading]);
  useEffect(() => {
    methods.setValue(AZURE_ONBOARDING_FIELDS.IS_MSP, isMSP);
  }, [isMSP]);
  useEffect(() => {
    if (!onboarding || !accountId || accountId === methods.getValues(AZURE_ONBOARDING_FIELDS.ACCOUNT_ID)) {
      return;
    }
    if (!onboarding?.accountId) {
      methods.reset({
        ...DEFAULT_FORM_STATE,
        [AZURE_ONBOARDING_FIELDS.ACCOUNT_ID]: accountId,
      });
    } else {
      const newScreen = screen || getPageForPausedOnboarding(onboarding);
      methods.reset({
        ...DEFAULT_FORM_STATE,
        ...onboarding,
        [AZURE_ONBOARDING_FIELDS.ACCOUNT_ID]: onboarding.accountId,
        [AZURE_ONBOARDING_FIELDS.ACCOUNT_NAME]: onboarding.accountName,
        [AZURE_ONBOARDING_FIELDS.ENROLLMENT_ID]: onboarding.enrollmentId,
        [AZURE_ONBOARDING_FIELDS.DIRECTORY_ID]: onboarding.directoryId,
        [AZURE_ONBOARDING_FIELDS.IS_MULTIPLE_FILES]: onboarding.isMultipleFiles,
        [AZURE_ONBOARDING_FIELDS.FILTER_OUT_MICROSOFT_365]: onboarding.filterOutMicrosoft365,
        [AZURE_ONBOARDING_FIELDS.AUTO_CREATE_CUSTOMERS_PROPERTY]: onboarding.autoCreateCustomersProperty,
        [AZURE_ONBOARDING_FIELDS.IS_MULTIPLE_SOURCES]: onboarding.isMultipleSources,
        [AZURE_ONBOARDING_FIELDS.DIRECTORY_PREFIX]: onboarding.directoryPrefix,
        [AZURE_ONBOARDING_FIELDS.APPLICATION_ID]: onboarding.applicationId,
        [AZURE_ONBOARDING_FIELDS.APPLICATION_DISPLAY_NAME]: onboarding.applicationDisplayName,
        [AZURE_ONBOARDING_FIELDS.CLIENT_SECRET]: onboarding.clientSecret,
        [AZURE_ONBOARDING_FIELDS.ACCOUNT_STORAGE]: onboarding.accountStorage,
        [AZURE_ONBOARDING_FIELDS.CONTAINER_NAME]: onboarding.containerName,
        [AZURE_ONBOARDING_FIELDS.CURRENCY_TYPE]: onboarding.currencyType,
        [AZURE_ONBOARDING_FIELDS.STARTED]: true,
        [AZURE_ONBOARDING_FIELDS.ONBOARDING_TYPE]: onboarding.onboardingType,
        [AZURE_ONBOARDING_FIELDS.VALIDATION_STARTED]: onboarding.validationStarted,
        [AZURE_ONBOARDING_FIELDS.ACCOUNT_TYPE]: onboarding.accountType,
        [AZURE_ONBOARDING_FIELDS.AUTO_ASSIGN_LINKED_ACCOUNTS]: onboarding.autoAssignLinkedAccounts,
        [AZURE_ONBOARDING_FIELDS.SCREEN]: newScreen,
      });
    }
  }, [onboarding?.accountId]);
  return (
    <FormProvider {...methods}>
      {isLoadingDebounced ? (
        <div className={styles.loadingPage}>
          <img src={LoadingGif} alt="loading" />
        </div>
      ) : (
        <div className="h-100">
          <AZUREOnboarding usersStore={usersStore} />
          {onboarding?.validationStatus?.errors?.length ? (
            <WarningsList warnings={onboarding.validationStatus.errors} />
          ) : null}
        </div>
      )}
    </FormProvider>
  );
});

AZUREOnboardingWrapper.propTypes = {
  usersStore: PropTypes.object.isRequired,
};
AZUREOnboarding.propTypes = {
  usersStore: PropTypes.object.isRequired,
};

export default AZUREOnboardingWrapper;
