import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import useRoles from 'users/new-user-management/hooks/reactQuery/useRoles';
import Spinner from 'shared/components/andtComponents/Spinner';
import SwitchButton from 'shared/components/andtComponents/Switch';
import Checkbox from 'shared/components/andtComponents/Checkbox';
import PermissionsExplanation from './PermissionsExplaination';
import { PERMISSION_ACTIONS, PERMISSION_ENTITIES, PERMISSION_TYPES } from '../../consts';
import Permission from './Permission';

import styles from './EffectivePermissionsTab.module.scss';

const EffectivePermissionsTab = ({ row }) => {
  const [filterByAvailablePermissions, setFilterByAvailablePermissions] = useState(true);
  const [permissionsToDisplay, setPermissionsToDisplay] = useState(null);
  const { fetchRolePermissions, setRolePermissions } = useRoles();
  const { data, isLoading } = fetchRolePermissions(row?.identifier?.internalName);

  useEffect(() => {
    if (data?.permissions) {
      if (filterByAvailablePermissions) {
        const permissions = data.permissions.filter((p) =>
          p.actionPermissions.some((ap) => ap.permissions.some((p) => p.permissionType !== PERMISSION_TYPES.NONE.id)),
        );
        setPermissionsToDisplay(permissions);
      } else {
        const allCategoriesPermissions = Object.keys(PERMISSION_ENTITIES).map((category) => ({
          category,
          actionPermissions: Object.values(PERMISSION_ACTIONS).map((action) => ({
            action: action.id,
            permissions: [],
          })),
        }));
        setPermissionsToDisplay(
          [...allCategoriesPermissions, ...data.permissions].sort((a, b) => a.category.localeCompare(b.category)),
        );
      }
    }
  }, [data?.permissions, filterByAvailablePermissions]);

  const getCategoryNewPermissions = (categoryIndex, actionId, newPermission) => {
    const categoryActionPermission = {
      permissionType: newPermission ? PERMISSION_TYPES.FULL.id : PERMISSION_TYPES.NONE.id,
    };

    return {
      category: data?.permissions[categoryIndex].category,
      actionPermissions: [
        {
          action: actionId,
          permissions: [categoryActionPermission],
        },
      ],
    };
  };

  const onPermissionChange = (categoryPermissions, actionId, newPermission) => {
    const categoryIndex = data?.permissions?.findIndex((c) => c.category === categoryPermissions?.category);
    if (categoryIndex === -1) {
      return;
    }
    const categoryNewPermissions = getCategoryNewPermissions(categoryIndex, actionId, newPermission);

    setRolePermissions.mutate({
      roleInternalName: row?.identifier?.internalName,
      roleCategoryPermissions: [categoryNewPermissions],
    });
  };

  const onActionPermissionHeaderChange = (actionId, newPermission) => {
    const categoryPermissions = [];
    data?.permissions?.forEach((categoryPermission, index) => {
      const newCategoryPermissions = getCategoryNewPermissions(index, actionId, newPermission);
      categoryPermissions.push(newCategoryPermissions);
    });
    setRolePermissions.mutate({
      roleInternalName: row?.identifier?.internalName,
      roleCategoryPermissions: categoryPermissions,
    });
  };

  const isCategoryActionHeaderChecked = (actionId) => {
    const actionPermissions = data?.permissions?.flatMap((categoryPermissions) =>
      categoryPermissions?.actionPermissions?.filter((actionPermissions) => actionPermissions.action === actionId),
    );
    return (
      actionPermissions?.length &&
      actionPermissions?.every((ac) => ac.permissions.some((p) => p.permissionType === PERMISSION_TYPES.FULL.id))
    );
  };

  return (
    <>
      {isLoading ? (
        <div className="position-relative">
          <Spinner />
        </div>
      ) : (
        <div className={styles.effectivePermissionsContainer}>
          <div className={styles.titleContainer}>
            <div>Effective Permissions</div>
            <Checkbox
              className={styles.showAvailablePermissions}
              isChecked={filterByAvailablePermissions}
              onChange={() => {
                setFilterByAvailablePermissions(!filterByAvailablePermissions);
              }}
              primary
              text="Show all available permissions"
            />
          </div>
          <div className={styles.container}>
            <div className={styles.permissionsGrid}>
              <>
                <div className={classNames(styles.gridCell, styles.headerCell, styles.entityHeaderCell)}>Entities</div>
                {Object.values(PERMISSION_ACTIONS).map((action) => (
                  <div className={classNames(styles.gridCell, styles.headerCell)}>
                    <div>{action.name}</div>
                    <SwitchButton
                      onChange={() => onActionPermissionHeaderChange(action.id)}
                      isChecked={isCategoryActionHeaderChecked(action.id)}
                      className={styles.permissionSwitch}
                      isSmall
                    />
                  </div>
                ))}
              </>
              {Array.isArray(permissionsToDisplay) &&
                permissionsToDisplay?.map((categoryPermissions) => (
                  <>
                    <div className={classNames(styles.gridCell, styles.entityCell)}>
                      {PERMISSION_ENTITIES[categoryPermissions.category]?.title || categoryPermissions.category}
                    </div>
                    {Object.values(PERMISSION_ACTIONS).map((action) => (
                      <div className={classNames(styles.gridCell, styles.valuesCell)}>
                        <Permission
                          actionId={action.id}
                          category={categoryPermissions.category}
                          onPermissionChange={(newPermission) =>
                            onPermissionChange(categoryPermissions, action.id, newPermission)
                          }
                          permissions={
                            categoryPermissions?.actionPermissions?.find((p) => p.action === action.id)?.permissions
                          }
                          roleInternalName={row?.identifier?.internalName}
                        />
                      </div>
                    ))}
                  </>
                ))}
            </div>
            <PermissionsExplanation />
          </div>
        </div>
      )}
    </>
  );
};

export default EffectivePermissionsTab;
